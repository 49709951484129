var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bv-example-row"},[_c('b-row',[_c('b-modal',{staticClass:"select-company-modal",attrs:{"title":"Users list","size":"md","hide-footer":"","scrollable":""},on:{"click":function($event){_vm.smallModal = false}},model:{value:(_vm.smallModal),callback:function ($$v) {_vm.smallModal=$$v},expression:"smallModal"}},[_c('div',{staticClass:"company-select-div"},[_c('b-input-group',{staticClass:"search-input-con"},[_c('b-form-input',{staticClass:"search-input",attrs:{"id":"input-default","placeholder":"Name","debounce":"400"},model:{value:(_vm.memberName),callback:function ($$v) {_vm.memberName=$$v},expression:"memberName"}}),_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text search-icon-block"},[_c('i',{staticClass:"fa fa-search"})])])],1),_vm._l((_vm.userList),function(ref){
          var full_name = ref.full_name;
          var prof_pic = ref.prof_pic;
          var job_title = ref.job_title;
          var company_name = ref.company_name;
          var customer_id = ref.customer_id;
          var user_id = ref.user_id;
return _c('b-row',{key:customer_id,staticClass:"company-select-row",on:{"click":function($event){return _vm.selectUser({ full_name: full_name, customer_id: customer_id, user_id: user_id })}}},[_c('b-col',[_c('div',{staticClass:"d-flex mt-2 mb-2"},[_c('img',{staticClass:"company-img",attrs:{"src":prof_pic}}),_c('div',{staticClass:"p-1"},[_c('p',{staticClass:"user-name"},[_vm._v("\n                  "+_vm._s(full_name)+"\n                ")]),_c('p',{staticClass:"user-position"},[_vm._v("\n                  "+_vm._s(job_title)+"\n                ")]),_c('p',{staticClass:"user-company-name"},[_vm._v("\n                  "+_vm._s(company_name)+"\n                ")])])])])],1)}),(_vm.nextPage)?_c('button',{staticClass:"load-users-for-tag",on:{"click":_vm.loadMore}},[_vm._v("\n          Load more users\n        ")]):_vm._e()],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }