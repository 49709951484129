import VueGallery from 'vue-gallery';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    resetUploaders: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    gallery: VueGallery,
  },
  data() {
    return {
      images: [],
      index: null,
    };
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    setupReader(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evn) => {
        this.images.push(evn.target.result);
      };
    },
    async onFilePicked(e) {
      const { files } = e.target;
      let images = [...files];
      if (images.length > 10) {
        images = images.slice(0, 10);
      }
      if (images[0] !== undefined) {
        images.forEach((file) => {
          this.setupReader(file);
        });
      }
    },
    removeImage(rmImg, index, e) {
      if (e) {
        e.preventDefault();
      }
      this.$emit('addToTrash', index);
      this.images = this.images.filter(img => img !== rmImg);
      this.$refs.image.imgValue = null;
    },
  },
  watch: {
    images: {
      immediate: true,
      handler(imgData) {
        this.$emit('input', imgData);
      },
      deep: true,

    },
    value: {
      handler(val) {
        this.images = val;
      },
      deep: true,
    },
    resetUploaders(val) {
      if (val) {
        this.images = [];
        this.index = null;
        this.$emit('resetDone', true);
      }
    },
  },
};
