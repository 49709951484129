<template>
  <b-container fluid class="mb-4" id="post-page">
    <b-form-textarea
      v-model="content"
      class="post-textarea"
      id="textarea-no-resize"
      placeholder="Write here or use @ to tag someone"
      rows="3"
      no-resize
      maxlength="2000"
      name="content"
      :class="{ 'is-danger': errors.has('content') }"
      @keydown="tagUser"
    ></b-form-textarea>
    <div class="main-post-box">
      <div class="box">
        <UploadVideo
          v-if="showVideo"
          v-model="videoUrl"
          @isUrlValid="checkUrlVideoValidation"
        />
        <UploadImg
          v-model="images"
          :resetUploaders="resetUploaders"
          @resetDone="resetUploaders = false"
        />

        <button @click="formSubmit" class="post-input-button">Post</button>
         <div class="switch-box">
          <b-form-checkbox v-model="addInCompanyWall" name="switch" switch />
          <span class="switch-text">Post to Company Wall</span>
        </div>
      </div>
      <UserTagSelect v-model="isOpenTag" @tag-user-post="addPostTag" />
    </div>
  </b-container>
</template>

<script>
import PostInput from '../../utils/mixins/PostInput';
import UploadImg from './UploadImg.vue';
import UploadVideo from './UploadVideo.vue';

export default {
  name: 'PostInput',
  mixins: [PostInput],
  components: {
    UploadImg,
    UploadVideo,
  },
};
</script>
<style >
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}
.mention-selected {
  background: rgb(192, 250, 153);
}
</style>

<style scoped>
#post-page {
  background-color: white;
  padding: 0;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.post-textarea {
  width: 100%;
}

.video-upload {
  margin-right: 10px;
}
.box {
  padding: 32px 14px 8px 24px;
  margin-bottom: 2px;
}
.form-control {
  border: none;
  border-bottom: 1px solid #d6dad6;
  box-shadow: 0px 0px 0px 0px;
  padding: 11px 1px 1px 14px;
}
::placeholder {
  resize: none;
  font-size: 14px;
  font-family: arial;
}
.post-input-button {
  border-radius: 0 10px 10px 10px;
  float: right;
  background: #bd9d5f;
  border: none;
  color: white;
  width: 50px;
  margin-top: -24px;
  font-size: 15px;
}
.switch-box {
  float: right;
  width: 62%;
  margin-top: -22px;
  height: 20px;
}
.switch-text {
  font-size: 12px;
  color: #666666;
  position: relative;
  left: 35px;
  bottom: 25px;
}
.text-images {
  width: 92px;
  float: left;
  cursor: pointer;
  color: #666666;
  font-size: 12px;
  margin-bottom: 0;
}

.text-videos {
  width: 248px;
  cursor: pointer;
  margin-bottom: 4px;
  color: #666666;
  font-size: 12px;
}
.field-text {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ff0000;
  transition: 0.5s;
}
</style>
