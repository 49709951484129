

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCollapse: false,
      videoLink: '',
    };
  },
  methods: {
    toggleClick() {
      this.showCollapse = !this.showCollapse;
    },
    getHostName(url) {
      let videoType = '';
      const regexYoutube = new RegExp(/(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/);
      const regexVimeo = new RegExp(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/);
      const isYoutubeUrl = regexYoutube.test(url);
      const isVimeoUrl = regexVimeo.test(url);
      if (isYoutubeUrl) videoType = 'youtube';
      if (isVimeoUrl) videoType = 'vimeo';
      return videoType;
    },
  },
  watch: {
    videoLink: {
      async handler(val) {
        const isValid = await this.$validator.validate();
        this.$emit('input', val);
        if (isValid) {
          this.$emit('isUrlValid', isValid);
        }
      },
    },
    value: {
      immediate: true,
      handler(val) {
        if (!val.length) {
          this.$validator.reset();
        } else {
          this.showCollapse = true;
        }
        this.videoLink = val;
      },
    },
  },
};
