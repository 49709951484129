<template>
  <b-container class="bv-example-row">
    <b-row>
      <b-modal
        v-model="smallModal"
        title="Users list"
        size="md"
        class="select-company-modal"
        hide-footer
        scrollable
        @click="smallModal = false"
      >
        <div class="company-select-div">
          <b-input-group class="search-input-con">
            <b-form-input
              id="input-default"
              v-model="memberName"
              placeholder="Name"
              class="search-input"
              debounce="400"
            />
            <div class="input-group-prepend">
              <span class="input-group-text search-icon-block">
                <i class="fa fa-search" />
              </span>
            </div>
          </b-input-group>
          <b-row
            v-for="{
              full_name,
              prof_pic,
              job_title,
              company_name,
              customer_id,
              user_id,
            } in userList"
            :key="customer_id"
            class="company-select-row"
            @click="selectUser({ full_name, customer_id, user_id })"
          >
            <b-col>
              <div class="d-flex mt-2 mb-2">
                <img :src="prof_pic" class="company-img" />
                <div class="p-1">
                  <p class="user-name">
                    {{ full_name }}
                  </p>
                  <p class="user-position">
                    {{ job_title }}
                  </p>
                  <p class="user-company-name">
                    {{ company_name }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <button v-if="nextPage" class="load-users-for-tag" @click="loadMore">
            Load more users
          </button>
        </div>
      </b-modal>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserTagSelect',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      smallModal: false,
      userList: [],
      memberName: '',
      page: 1,
      nextPage: true,
      companyId: 19,
    };
  },
  watch: {
    value: {
      async handler(val) {
        if (val && this.page === 1) {
          await this.getUsersList(this.page, this.memberName);
        }
        this.smallModal = val;
      },
    },
    async memberName() {
      this.userList = [];
      this.page = 1;
      await this.getUsersList(this.page, this.memberName);
    },
    smallModal(val) {
      if (!val) {
        this.memberName = '';
      }
      this.$emit('input', val);
    },
  },
  methods: {
    async loadMore() {
      await this.getUsersList(this.page, this.memberName);
    },
    async getUsersList(page, name) {
      const { results, next } = await this.findCustomers({ page, name });
      this.userList = [...this.userList, ...results];
      this.nextPage = !!next;
      this.page = page + 1;
    },
    ...mapActions({
      findCustomers: 'user/findCustomers',
    }),
    selectUser(user) {
      this.$emit('tag-user-post', user);
      this.smallModal = false;
    },
  },
};
</script>

<style scoped>
.sponsors-icon {
  color: #bd9d5f;
}
.company-photo {
  width: 20px;
  height: 20px;
}
.select-company-modal {
  height: 100%;
}
.company-select-div {
  overflow-y: auto;
  overflow-x: hidden;
}
.company-img {
  height: 45px;
  width: 45px;
}
.user-name {
  font-family: Arial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold;
  color: #333333;
  margin: 0;
}
.user-position {
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold;
  color: #666666;
  margin: 0;
}
.user-company-name {
  font-family: Arial;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold;
  color: #bd9d5f;
  margin: 0;
}
.company-select-row:hover {
  cursor: pointer;
  background-color: #bd9d5f;
}
.search-icon-block {
  height: 30px;
  padding: 6px;
  background-color: transparent;
  border: 1px solid #aaaaaa;
  border-left: none;
}
.search-input {
  box-shadow: none;
  border: 1px solid #aaaaaa;
  border-right: none;
  height: 30px;
}
.search-input:focus {
  border-color: #aaaaaa;
  box-shadow: none;
}
.search-input-con {
  padding-right: 1px;
  margin-bottom: 5px;
}
.sponsors-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
}
.sponsors-image-remove-btn {
  background: #00ffff00;
  border: navajowhite;
  float: right;
}
.sponsors-post-image-delete {
  color: #bd9d5f;
  border-radius: 50%;
  background-color: white;
}
.main-user-info {
  border: 1px solid #e4e5e6;
  display: flex;
  flex-direction: row;
}
.user-img {
  width: 75px;
  height: 75px;
  cursor: pointer;
  float: left;
}
.user-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.username {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 12px;
  margin: 0;
}
.user-position {
  text-transform: capitalize;
  color: #666666;
  font-size: 10px;
}
.created-time {
  color: #9c9999;
  font-size: 10px;
}
.user-info {
  padding: 0 10px;
}
.group-contact-count-icon {
  color: #bd9d5f;
  cursor: pointer;
}
.group-contact-count-span {
  font-family: Arial;
  font-size: 17px;
}
.load-users-for-tag {
  width: 100%;
  background: none;
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  font-size: 12px;
  margin-bottom: 25px;
}
.load-users-for-tag:hover {
  background-color: #bd9d5f;
  color: white;
  transition: 0.5s;
}
</style>
