<template>
  <div>
    <b-collapse
      v-model="showCollapse"
      class="mb-5"
      id="video-collapse"
    >
      <div class="input-for-comment">
        <b-input
          v-validate="{
            required: true,
            regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
          }"
          v-model="videoLink"
          name="link"
          id="link"
          class="link-input"
          type="text"
          placeholder="Put the video link here"
        ></b-input>
        <small
          v-if="errors.has('link')"
          class="field-text is-danger"
        >
          {{ errors.first('link') }}
        </small>
      </div>
      <b-container class="video-preview-container">
        <b-row>
          <b-col class="mt-3 p-0">
        <video-embed :src="videoLink" v-if="getHostName(videoLink)"></video-embed>

          </b-col>
        </b-row>
      </b-container>
    </b-collapse>
     <div class="video-upload" >
      <i
        class="fas fa-video"
        @click="toggleClick"
      ><span class="post-video-text"> Video</span></i>
    </div>
  </div>
</template>

<script>
import VideoUploader from '../../utils/mixins/VideoUploader';

export default {
  name: 'UploadVideo',
  mixins: [VideoUploader],
};
</script>

<style scoped>
.video-upload {
  font-size: 14px;
  cursor: pointer;
  float: left;
  margin: -21px 75px;
  color: #666666;
}
.video-upload-mobile{
  color: #dbdbdb;
  margin-top: 10px;
}
.video-upload-mobile i{
  font-size: 13px;
}
.field-text {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ff0000;
  transition: 0.5s;
}
.link-input {
  border: 1px solid #aaaaaa;
  font-size: 12px;
  font-family: Arial;
  line-height: 1.17;
}
.link-input:focus {
  box-shadow: none;
}
.post-video-text {
  font-family: HelveticaNeue;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
}
.card-title {
  padding: 15px;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.preview-card {
  border: solid 1px #dbdbdb;
}
</style>
<style>
.video-preview-container .spinner {
  margin-top: 0 !important;
}
</style>
