<template>
  <div>
    <div class="image-uploader-box">
      <b-container class="img-uploader">
        <b-row :class="{'post-image-row': value.length}">
          <gallery
            :images="value"
            :index="index"
            @close="index = null"
          ></gallery>
          <b-col
            v-for="(image, imageIndex) in value"
            class="image"
            cols="4"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')', 'max-width': '100px', height: '75px' }"
          >
            <button
              class="remove-btn-img"
              @click.stop="removeImage(image, imageIndex)"
            >
              <i class="fa fa-close post-image-delete"></i>
            </button>
          </b-col>
        </b-row>
      </b-container>
      <input
        multiple
        ref="image"
        type="file"
        style="display: none"
        accept="image/*"
        @change="onFilePicked"
        :disabled="value.length>=10"
      >
    </div>
    <figure class="post-figure-one" >
      <i
        class="fas fa-images"
        placeholder="Select Image"
        @click="pickFile"
        title="Upload max 10"
      > <span class="post-image-text">Images</span></i>
    </figure>
  </div>
</template>


<script>
import ImgUploader from '../../utils/mixins/ImgUploader';

export default {
  name: 'UploadImg',
  mixins: [ImgUploader],
};
</script>


<style>
.upload-img-size {
  width: 200px;
}
.post-figure-one {
  font-size: 13px;
  cursor: pointer;
  float: left;
  margin: -21px 0px;
  color: #666666;
}
.post-figure-one-mobile{
  color: #dbdbdb;
  margin-bottom: 0;
  margin-top: 10px;
}
.post-figure-one-mobile i{
  font-size: 15px;

}
figure:hover {
  color: #BD9D5F;
  transition: 0.4s;
}

.remove-btn {
  background: #00ffff00;
  border: navajowhite;
}
.remove-btn:hover {
  color: #BD9D5F;
}
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.image-uploader-box a:not([href]):not([tabindex]) {
  color: #fff;
  text-decoration: none;
}
.post-image-text {
  font-family: HelveticaNeue;
  font-size: 12px;
  font-weight: normal;
  margin-left: 5px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
}
.post-image-row {
  padding-bottom: 30px;
}
.remove-btn-img {
  background: #00ffff00;
  border: navajowhite;
  position: absolute;
  bottom: 50px;
  left: 73px;
}
.post-image-delete {
  background: #BD9D5F;
  color: white;
  padding: 3px;
  font-size: 14px;
  border-radius: 50px;
}
</style>
